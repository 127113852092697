/**
 * XAPI
 * This OData service is located at /xapi/v1
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { XapiBaseService }                                   from "../../../xapi-base.service";
import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { LicenseStatusEditLicenseInfoRequestBody } from '../model/license-status-edit-license-info-request-body';
// @ts-ignore
import { LicenseStatusLinkPartnerRequestBody } from '../model/license-status-link-partner-request-body';
// @ts-ignore
import { LicenseStatusReplaceLicenseKeyRequestBody } from '../model/license-status-replace-license-key-request-body';
// @ts-ignore
import { PbxLicenseStatus } from '../model/pbx-license-status';
// @ts-ignore
import { PbxODataErrorsODataError } from '../model/pbx-o-data-errors-o-data-error';
// @ts-ignore
import { PbxResellerInfo } from '../model/pbx-reseller-info';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface EditLicenseInfoRequestParams {
    /** Action parameters */
    licenseStatusEditLicenseInfoRequestBody: LicenseStatusEditLicenseInfoRequestBody;
}

export interface GetLicenseStatusRequestParams {
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface LinkPartnerRequestParams {
    /** Action parameters */
    licenseStatusLinkPartnerRequestBody: LicenseStatusLinkPartnerRequestBody;
}

export interface PartnerInfoRequestParams {
    /** Usage: resellerId&#x3D;{resellerId} */
    resellerId: string;
}

export interface ReplaceLicenseKeyRequestParams {
    /** Action parameters */
    licenseStatusReplaceLicenseKeyRequestBody: LicenseStatusReplaceLicenseKeyRequestBody;
}


@Injectable({
  providedIn: 'root'
})
export class LicenseStatusApiService extends XapiBaseService {


    /**
     * Invoke action EditLicenseInfo
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editLicenseInfo(requestParameters?: EditLicenseInfoRequestParams, observe?: 'body'): Observable<any>;
    public editLicenseInfo(requestParameters?: EditLicenseInfoRequestParams, observe?: 'response'): Observable<HttpResponse<any>>;
    public editLicenseInfo(requestParameters?: EditLicenseInfoRequestParams, observe?: 'events'): Observable<HttpEvent<any>>;
    public editLicenseInfo(requestParameters?: EditLicenseInfoRequestParams, observe: any = 'body'): Observable<any> {
        const licenseStatusEditLicenseInfoRequestBody = requestParameters?.licenseStatusEditLicenseInfoRequestBody;
        if (licenseStatusEditLicenseInfoRequestBody === null || licenseStatusEditLicenseInfoRequestBody === undefined) {
            throw new Error('Required parameter licenseStatusEditLicenseInfoRequestBody was null or undefined when calling editLicenseInfo.');
        }

        let localVarHeaders = this.defaultHeaders;

            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/LicenseStatus/Pbx.EditLicenseInfo`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: licenseStatusEditLicenseInfoRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe
            }
        );
    }
    public editLicenseInfoQuery(requestParameters: EditLicenseInfoRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public editLicenseInfoQuery(requestParameters: EditLicenseInfoRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public editLicenseInfoQuery(requestParameters: EditLicenseInfoRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public editLicenseInfoQuery(requestParameters: EditLicenseInfoRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const licenseStatusEditLicenseInfoRequestBody = requestParameters.licenseStatusEditLicenseInfoRequestBody;
        if (licenseStatusEditLicenseInfoRequestBody === null || licenseStatusEditLicenseInfoRequestBody === undefined) {
        throw new Error('Required parameter licenseStatusEditLicenseInfoRequestBody was null or undefined when calling editLicenseInfo.');
        }

    return `/LicenseStatus/Pbx.EditLicenseInfo`;
}

    /**
     * Get LicenseStatus
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLicenseStatus(requestParameters?: GetLicenseStatusRequestParams, observe?: 'body'): Observable<PbxLicenseStatus>;
    public getLicenseStatus(requestParameters?: GetLicenseStatusRequestParams, observe?: 'response'): Observable<HttpResponse<PbxLicenseStatus>>;
    public getLicenseStatus(requestParameters?: GetLicenseStatusRequestParams, observe?: 'events'): Observable<HttpEvent<PbxLicenseStatus>>;
    public getLicenseStatus(requestParameters?: GetLicenseStatusRequestParams, observe: any = 'body'): Observable<any> {
        const $select = requestParameters?.$select;
        const $expand = requestParameters?.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/LicenseStatus`;
        return this.httpClient.request<PbxLicenseStatus>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe
            }
        );
    }
    public getLicenseStatusQuery(requestParameters: GetLicenseStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getLicenseStatusQuery(requestParameters: GetLicenseStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getLicenseStatusQuery(requestParameters: GetLicenseStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getLicenseStatusQuery(requestParameters: GetLicenseStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/LicenseStatus`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Invoke action LinkPartner
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public linkPartner(requestParameters?: LinkPartnerRequestParams, observe?: 'body'): Observable<any>;
    public linkPartner(requestParameters?: LinkPartnerRequestParams, observe?: 'response'): Observable<HttpResponse<any>>;
    public linkPartner(requestParameters?: LinkPartnerRequestParams, observe?: 'events'): Observable<HttpEvent<any>>;
    public linkPartner(requestParameters?: LinkPartnerRequestParams, observe: any = 'body'): Observable<any> {
        const licenseStatusLinkPartnerRequestBody = requestParameters?.licenseStatusLinkPartnerRequestBody;
        if (licenseStatusLinkPartnerRequestBody === null || licenseStatusLinkPartnerRequestBody === undefined) {
            throw new Error('Required parameter licenseStatusLinkPartnerRequestBody was null or undefined when calling linkPartner.');
        }

        let localVarHeaders = this.defaultHeaders;

            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/LicenseStatus/Pbx.LinkPartner`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: licenseStatusLinkPartnerRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe
            }
        );
    }
    public linkPartnerQuery(requestParameters: LinkPartnerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public linkPartnerQuery(requestParameters: LinkPartnerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public linkPartnerQuery(requestParameters: LinkPartnerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public linkPartnerQuery(requestParameters: LinkPartnerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const licenseStatusLinkPartnerRequestBody = requestParameters.licenseStatusLinkPartnerRequestBody;
        if (licenseStatusLinkPartnerRequestBody === null || licenseStatusLinkPartnerRequestBody === undefined) {
        throw new Error('Required parameter licenseStatusLinkPartnerRequestBody was null or undefined when calling linkPartner.');
        }

    return `/LicenseStatus/Pbx.LinkPartner`;
}

    /**
     * Invoke function PartnerInfo
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public partnerInfo(requestParameters?: PartnerInfoRequestParams, observe?: 'body'): Observable<PbxResellerInfo>;
    public partnerInfo(requestParameters?: PartnerInfoRequestParams, observe?: 'response'): Observable<HttpResponse<PbxResellerInfo>>;
    public partnerInfo(requestParameters?: PartnerInfoRequestParams, observe?: 'events'): Observable<HttpEvent<PbxResellerInfo>>;
    public partnerInfo(requestParameters?: PartnerInfoRequestParams, observe: any = 'body'): Observable<any> {
        const resellerId = requestParameters?.resellerId;
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling partnerInfo.');
        }

        let localVarHeaders = this.defaultHeaders;

            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/LicenseStatus/Pbx.PartnerInfo(resellerId=${this.configuration.encodeParam({name: "resellerId", value: resellerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})})`;
        return this.httpClient.request<PbxResellerInfo>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe
            }
        );
    }
    public partnerInfoQuery(requestParameters: PartnerInfoRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public partnerInfoQuery(requestParameters: PartnerInfoRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public partnerInfoQuery(requestParameters: PartnerInfoRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public partnerInfoQuery(requestParameters: PartnerInfoRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const resellerId = requestParameters.resellerId;
        if (resellerId === null || resellerId === undefined) {
        throw new Error('Required parameter resellerId was null or undefined when calling partnerInfo.');
        }

    return `/LicenseStatus/Pbx.PartnerInfo(resellerId=${this.configuration.encodeParam({name: "resellerId", value: resellerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})})`;
}

    /**
     * Invoke action RefreshLicenseStatus
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public refreshLicenseStatus(observe?: 'body'): Observable<any>;
    public refreshLicenseStatus(observe?: 'response'): Observable<HttpResponse<any>>;
    public refreshLicenseStatus(observe?: 'events'): Observable<HttpEvent<any>>;
    public refreshLicenseStatus(observe: any = 'body'): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/LicenseStatus/Pbx.RefreshLicenseStatus`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe
            }
        );
    }
    public refreshLicenseStatusQuery(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public refreshLicenseStatusQuery(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public refreshLicenseStatusQuery(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public refreshLicenseStatusQuery(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {

    return `/LicenseStatus/Pbx.RefreshLicenseStatus`;
}

    /**
     * Invoke action ReplaceLicenseKey
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public replaceLicenseKey(requestParameters?: ReplaceLicenseKeyRequestParams, observe?: 'body'): Observable<any>;
    public replaceLicenseKey(requestParameters?: ReplaceLicenseKeyRequestParams, observe?: 'response'): Observable<HttpResponse<any>>;
    public replaceLicenseKey(requestParameters?: ReplaceLicenseKeyRequestParams, observe?: 'events'): Observable<HttpEvent<any>>;
    public replaceLicenseKey(requestParameters?: ReplaceLicenseKeyRequestParams, observe: any = 'body'): Observable<any> {
        const licenseStatusReplaceLicenseKeyRequestBody = requestParameters?.licenseStatusReplaceLicenseKeyRequestBody;
        if (licenseStatusReplaceLicenseKeyRequestBody === null || licenseStatusReplaceLicenseKeyRequestBody === undefined) {
            throw new Error('Required parameter licenseStatusReplaceLicenseKeyRequestBody was null or undefined when calling replaceLicenseKey.');
        }

        let localVarHeaders = this.defaultHeaders;

            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/LicenseStatus/Pbx.ReplaceLicenseKey`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: licenseStatusReplaceLicenseKeyRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe
            }
        );
    }
    public replaceLicenseKeyQuery(requestParameters: ReplaceLicenseKeyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public replaceLicenseKeyQuery(requestParameters: ReplaceLicenseKeyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public replaceLicenseKeyQuery(requestParameters: ReplaceLicenseKeyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public replaceLicenseKeyQuery(requestParameters: ReplaceLicenseKeyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const licenseStatusReplaceLicenseKeyRequestBody = requestParameters.licenseStatusReplaceLicenseKeyRequestBody;
        if (licenseStatusReplaceLicenseKeyRequestBody === null || licenseStatusReplaceLicenseKeyRequestBody === undefined) {
        throw new Error('Required parameter licenseStatusReplaceLicenseKeyRequestBody was null or undefined when calling replaceLicenseKey.');
        }

    return `/LicenseStatus/Pbx.ReplaceLicenseKey`;
}

    /**
     * Invoke action UnlinkPartner
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unlinkPartner(observe?: 'body'): Observable<any>;
    public unlinkPartner(observe?: 'response'): Observable<HttpResponse<any>>;
    public unlinkPartner(observe?: 'events'): Observable<HttpEvent<any>>;
    public unlinkPartner(observe: any = 'body'): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/LicenseStatus/Pbx.UnlinkPartner`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe
            }
        );
    }
    public unlinkPartnerQuery(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public unlinkPartnerQuery(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public unlinkPartnerQuery(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public unlinkPartnerQuery(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {

    return `/LicenseStatus/Pbx.UnlinkPartner`;
}

}
